import React, { useState, useEffect } from 'react';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';

const LikeButton = ({ postId, currentUserId, initialLikes, initialLikeArray }) => {
  const [likes, setLikes] = useState(initialLikes);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsLiked(initialLikeArray.includes(currentUserId));
   
    // Disable the button for 3 seconds on load
    const timer = setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [initialLikeArray, currentUserId]);

  const handleLike = async () => {
    if (isDisabled) return;
    setIsDisabled(true);
    const postRef = doc(db, 'newsfeed', postId);
    try {
      if (isLiked) {
        // Unlike
        await updateDoc(postRef, {
          likes: likes - 1,
          likeArray: arrayRemove(currentUserId)
        });
        setLikes(prevLikes => prevLikes - 1);
        setIsLiked(false);
      } else {
        // Like
        await updateDoc(postRef, {
          likes: likes + 1,
          likeArray: arrayUnion(currentUserId)
        });
        setLikes(prevLikes => prevLikes + 1);
        setIsLiked(true);
      }
    } catch (error) {
      console.error("Error updating like status:", error);
      // Optionally, add user-facing error handling here
    } finally {
      setIsDisabled(false);
    }
  };

  const formatLikes = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + 'M';
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + 'K';
    }
    return count.toString();
  };
 
  return (
    <button
      className={`likes ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={handleLike}
      disabled={isDisabled}
      style={{
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        fontWeight: "bold",
      }}
    >
      <i className={`lni lni-heart${isLiked ? '-fill' : ''}`}></i>
      {formatLikes(likes)}
    </button>
  );
};

export default LikeButton;