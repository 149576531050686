import { createContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import axios from 'axios';
import { updateDoc } from "firebase/firestore";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [userDataFromDb, setUserDataFromDb] = useState({});
  const [userDataFromMongoDb, setUserDataFromMongoDb] = useState({});


  const LIVE_URL = process.env.REACT_APP_LIVE_URL;

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      try {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          const authToken = await user.getIdToken();

          if (userDocSnap.exists()) {
            // Combine user data with authToken
            const userData = {
              ...userDocSnap.data(),
              authToken,
            };

            setUserDataFromDb(userData);


            // Fetch user data from MongoDB if authToken is available
            if (authToken) {
              try {
                const queryParams = {
                  params: {
                    uid: user.uid,
                    email: user.email
                  },
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                  }
                };
                
                const response = await axios.get(`${LIVE_URL}/users-profile`, queryParams);
                const userProfileData = response.data;
      
                
                setUserDataFromMongoDb(userProfileData);

              } catch (error) {
                console.error('Error fetching user profile from MongoDB:', error);
              }
            }
          }
        }
      } catch (error) {
        console.log("Error getting user data:", error);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    //  currentUser => corrent user data FB, userDataFromDb => MORE DATA from FB, userDataFromMongoDb
    <AuthContext.Provider value={{ currentUser, userDataFromDb, userDataFromMongoDb }}>
      {children}
    </AuthContext.Provider>
  );
};
