import React, { useState, useEffect, useContext } from "react";
import { collection, getDocs, query, orderBy, limit, startAfter, deleteDoc, doc, where } from "firebase/firestore";
import { db } from "../firebase";
import "../style/Uninews.scss";
import { AuthContext } from "../context/AuthContext";
import AddNews from "../components/AddNews";
import profileDefaultCover from '../assets/profileDefaultCover.jpeg'
import LikeButton from "./NewsComponents/LikeButton";
import Comments from "./NewsComponents/NewsComments";
import initparticles from '../animation';

import ads from '../assets/1ads.gif'

const UniNews = () => {
    const [newsData, setNewsData] = useState([]);
    const [filteredNewsData, setFilteredNewsData] = useState([]);
    const { currentUser, userDataFromDb, userDataFromMongoDb } = useContext(AuthContext);
    const [expandedNews, setExpandedNews] = useState({});
    const [showCategoriesPopup, setShowCategoriesPopup] = useState(false);
    const [uniqueCategories, setUniqueCategories] = useState([]);
    const [showAddNewsPopup, setShowAddNewsPopup] = useState(false);
    const [topUsers, setTopUsers] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePopup, setActivePopup] = useState(null);
    const [newsImageInPopup, setNewsImageInPopup] = useState(false);
    const [newsImageInPopupURL, setNewsImageInPopupURL] = useState(""); 
    const [imagePopUpContent, setImagePopUpContent] = useState("");

    
  useEffect(() => { initparticles(); }, []);


    useEffect(() => {
        fetchNewsData();
        fetchTopUsers();
    }, []);

    useEffect(() => {
        handleFilterNews();
    }, [selectedCategories, newsData]);

    const fetchNewsData = async (loadMore = false) => {
        try {
            setLoading(true);
            const newsfeedCollectionRef = collection(db, "newsfeed");
            let q = query(
                newsfeedCollectionRef,
                where("isApproved", "==", true),
                limit(30) // Increased limit to ensure we have enough data after filtering
            );

            if (loadMore && lastVisible) {
                q = query(q, startAfter(lastVisible));
            }

            const querySnapshot = await getDocs(q);
            let newsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Sort the newsArray by createdTimeStamp in descending order
            newsArray.sort((a, b) => b.createdTimeStamp - a.createdTimeStamp);

            // Limit the array to 10 items after sorting
            newsArray = newsArray.slice(0, 10);

            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (loadMore) {
                setNewsData(prevData => [...prevData, ...newsArray]);
                setFilteredNewsData(prevData => [...prevData, ...newsArray]);
            } else {
                setNewsData(newsArray);
                setFilteredNewsData(newsArray);
            }

            const allCategories = newsArray.map(item => item.categories);
            setUniqueCategories([...new Set(allCategories)]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching news data:", error);
            setLoading(false);
        }
    };

    const fetchTopUsers = async () => {
        try {
            const usersQuery = query(collection(db, "users"), orderBy("accountCreated", "desc"), limit(10));
            const querySnapshot = await getDocs(usersQuery);
            setTopUsers(querySnapshot.docs.map(user => ({ id: user.id, ...user.data() })));
        } catch (error) {
            console.error("Error fetching top users:", error);
        }
    };

    const handleFilterNews = () => {
        if (selectedCategories.length === 0) {
            setFilteredNewsData(newsData);
        } else {
            const filteredNews = newsData.filter(newsItem => selectedCategories.includes(newsItem.categories));
            setFilteredNewsData(filteredNews);
        }
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories(prev => 
            prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]
        );
    };

    const handleClearFilters = () => setSelectedCategories([]);

    const toggleContent = (newsId) => {
        setExpandedNews(prevState => ({ ...prevState, [newsId]: !prevState[newsId] }));
    };

    const truncateContent = (content) => {
        const words = content.split(" ");
        return words.length > 40 ? words.slice(0, 40).join(" ") + "..." : content;
    };

    const convertTimestamp = (timestamp) => {
        const now = Date.now();
        const diff = now - timestamp;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (years > 0) return `${years}y ago`;
        if (months > 0) return `${months}m ago`;
        if (days > 0) return `${days}d ago`;
        if (hours > 0) return `${hours}h ago`;
        if (minutes > 0) return `${minutes}m ago`;
        return `${seconds}s ago`;
    };

    const handleCommentAdded = (postId, newComment, deletedComment = null) => {
        setFilteredNewsData(prevData =>
            prevData.map(item => {
                if (item.id === postId) {
                    if (deletedComment) {
                        return { ...item, comments: item.comments.filter(c => c !== deletedComment) };
                    } else if (newComment) {
                        return { ...item, comments: [...item.comments, newComment] };
                    }
                }
                return item;
            })
        );
    };

    const handleSmallPopup = (newsId) => {
        setActivePopup(activePopup === newsId ? null : newsId);
    };

    const handleDeletePost = async (postId) => {
        try {
            await deleteDoc(doc(db, "newsfeed", postId));
            setFilteredNewsData(prevData => prevData.filter(item => item.id !== postId));
            setNewsData(prevData => prevData.filter(item => item.id !== postId));
            setActivePopup(null);
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    };

    // go back to history
    const handleGoBack = () => {
        window.history.back();
    }

    const handleImagePopup = (imageURL, content) => {
        setNewsImageInPopupURL(imageURL);
        setImagePopUpContent(content);
        setNewsImageInPopup(true);
    };

    return (
        <div className="NewsPage">
            <div className="news-navbar">
                <h1 className="page-title"> 
                    {/* back icon */}
                    <i className="fas fa-arrow-left goback" onClick={()=>{handleGoBack()}}></i>
                      Campus Live</h1>
                <div className="filters">
                    {/* <div className="filter-item">
                        <p>Filters</p>
                        <i className="fad fa-filter"></i>
                    </div>
                    <div className="filter-item">
                        <p>Sort</p>
                        <i className="fad fa-sort"></i>
                    </div> */}
                    <div className="filter-item" onClick={() => setShowCategoriesPopup(!showCategoriesPopup)}>
                        <p>Categories</p>
                        <i className="fad fa-folder"></i>
                    </div>
                </div>
                <div className="menus">
                    <div className="buttons ">
                        <button className="button textcontainer" onClick={() => setShowAddNewsPopup(true)}>
                            <i className="fad fa-plus particletext bubbles"></i> Create Post
                        </button>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="left">
                    <div className="user-profile">
                        <div className="image" style={{ backgroundImage: `url(${profileDefaultCover})` }}>
                            <img src={userDataFromDb.photoURL} alt="" />
                        </div>
                        <div className="user-details">
                            <h2>{userDataFromDb.displayName}</h2>
                            <p>{userDataFromMongoDb.university}</p>
                            <p className="bio">{userDataFromMongoDb.bio}</p>
                        </div>
                    </div>
                    <div className="top-users">
                        <h3>New Users</h3>
                        <div className="user-list">
                            {topUsers.length > 0 ? (
                                topUsers.map((user) => (
                                    <div className="top-user" key={user.id} hover-data-id={user.displayName}>
                                        <div className="user-image">
                                            <img src={user.photoURL || profileDefaultCover} alt={user.displayName} />
                                        </div>
                                        <div>
                                            <h3>{user.displayName}</h3>
                                            <p>{user.university || "Student"}</p>
                                        </div>
                                        <div className="send-message" onClick={() => alert("Coming Soon!")}>
                                            <i className="fas fa-user-plus"></i>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No top users found.</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="center">
                    <div className="content">
                        <div className="shareSomething" style={{ display: showAddNewsPopup ? 'none' : '' }}>
                            <div className="inner" onClick={() => setShowAddNewsPopup(true)}>
                                <img src={userDataFromDb.photoURL} alt="" />
                                <p> Share Something... <span>🥳</span></p>
                            </div>
                        </div>

                        {filteredNewsData && filteredNewsData.length > 0 ? (
                            filteredNewsData.map((newsItem) => (
                                <div className="card" key={newsItem.id}>
                                    <div className="card-top">
                                        <div className="author">
                                            <img src={newsItem.authorProfile} alt={newsItem.authorName} />
                                            <div className="info">
                                                <h3>{newsItem.authorName}</h3>
                                                <p><i className="fas fa-clock"></i> {convertTimestamp(newsItem.createdTimeStamp)}</p>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <p className="type">#{newsItem.categories}</p>
                                            <i className="far fa-ellipsis-v" onClick={() => handleSmallPopup(newsItem.id)}></i>

                                            {activePopup === newsItem.id && (
                                            <div className="small-popup">
                                                <div className="small-popup-content">
                                                    <p>Share</p>
                                                    {currentUser.uid === newsItem.authorUid  && (
                                                        <p onClick={() => handleDeletePost(newsItem.id)}>Delete</p>
                                                    )}
                                                    <br />
                                                    <p onClick={() => handleSmallPopup(newsItem.id)}>Close</p>
                                                </div>
                                            </div>
                                        )}

                                        </div>
                                    </div>
                                    <div className="card-center">
                                        {newsItem.imageURL && (
                                            <div className="image">
                                                <img src={newsItem.imageURL} alt=""  onClick={() => handleImagePopup(newsItem.imageURL, newsItem.text)} />
                                            </div>
                                        )}
                                        <div className="news" dangerouslySetInnerHTML={{ 
                                            __html: expandedNews[newsItem.id] || newsItem.text.length < 40 
                                                ? newsItem.text 
                                                : truncateContent(newsItem.text) 
                                        }} />
                                        {newsItem.text.split(" ").length > 40 && (
                                            <p onClick={() => toggleContent(newsItem.id)} className="readMore">
                                                {expandedNews[newsItem.id] ? "See Less" : "See More"}
                                            </p>
                                        )}
                                        <hr />
                                        <div className="likes-comments">
                                            <div className="action">
                                                <LikeButton 
                                                    postId={newsItem.id} 
                                                    currentUserId={currentUser.uid}
                                                    initialLikes={newsItem.likes}
                                                    initialLikeArray={newsItem.likeArray || []}
                                                />
                                                <div className="comments">
                                                    <i className="lni lni-comments-alt-2"></i>
                                                    {newsItem.comments.length}
                                                </div>
                                            </div>
                                            <Comments
                                                postId={newsItem.id}
                                                comments={newsItem.comments}
                                                onCommentAdded={(newComment, deletedComment) => handleCommentAdded(newsItem.id, newComment, deletedComment)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No news data available.</p>
                        )}

                        <div className="load-more" onClick={() => fetchNewsData(true)} style={{ display: loading ? 'none' : '' }}>
                            <i className="fad fa-chevron-down"></i> Load More <i className="fad fa-chevron-down"></i>
                        </div>

                        {loading && <p className="NLoading">Loading...</p>}
                    </div>
                </div>

                <div className="right">
                    {/* <div className="topPost">
                        <h4>Top Posts</h4>
                        <div className="topPosts">
                            
                        </div>
                    </div> */}
                    <div className="ads">
                        <h3>Ads</h3>
                        <div className="ads-container">
                            <img src={ads} alt="ads" onClick={() => window.open("https://enally.in/contact", "_blank")} />
                        </div>

                    </div>
                </div>
            </div>

            {showCategoriesPopup && (
                <div className="categories-popup">
                    <div className="popup-content">
                        <h4>Categories</h4>
                        <div className="categories-list">
                            {uniqueCategories.map((category, index) => (
                                <label key={index}>
                                    <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCategoryChange(category)}
                                    />
                                    {category}
                                </label>
                            ))}
                        </div>
                        <button onClick={handleClearFilters}>Clear Filters</button>
                        <button className="close-popup" onClick={() => setShowCategoriesPopup(false)}>Close</button>
                    </div>
                </div>
            )}

            {showAddNewsPopup && (
                <div className="addNewsPopup">
                    <div className="addNewsContent">
                        <button className="closeButton" onClick={() => setShowAddNewsPopup(false)}>×</button>
                        <AddNews />
                    </div>
                </div>
            )}
            
            {newsImageInPopup && (
                <div className="newsImagePopup">
                    <div className="newsImageContent">
                        <button className="closeButton" onClick={() => setNewsImageInPopup(false)}>×</button>
                        <img src={newsImageInPopupURL} alt="News Image" />
                        <div className="content" dangerouslySetInnerHTML={{ __html: imagePopUpContent }} />
                    </div>
                </div>
            )}




        </div>
    );
};

export default UniNews;