import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { collection, addDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuid } from "uuid";
import EmojiPicker from "emoji-picker-react";
import { FaImage } from "react-icons/fa"; 

const MAX_CONTENT_LENGTH = 4000;

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'emoji'],
            ['clean']
        ],
    },
    clipboard: {
        matchVisual: false,
    },
};

const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link',
];

const AddNews = () => {
    const { userDataFromDb, currentUser } = useContext(AuthContext);
    const [newsContent, setNewsContent] = useState("");
    const [coverImage, setCoverImage] = useState(null);
    const [categories, setCategories] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadComplete, setUploadComplete] = useState("");
    const [errors, setErrors] = useState("");
    const [characterCount, setCharacterCount] = useState(0);    
    const [showEmojis, setShowEmojis] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const handleNewsContentChange = (content, delta, source, editor) => {
        const text = editor.getText();
        setCharacterCount(text.length);
        setNewsContent(content);
    };

    const handleEmojiClick = (emojiObject) => {
        setNewsContent((prevContent) => prevContent + emojiObject.emoji);
    };

    const handlePublish = async (e) => {
        e.preventDefault();

        setErrors(""); // Clear previous errors
        setIsSaved(false); // Reset saved state

        if (!newsContent || !categories) {
            setErrors("Please fill in all required fields.");
            return;
        }
        if (newsContent.length > MAX_CONTENT_LENGTH) {
            setErrors(`News content must be ${MAX_CONTENT_LENGTH} characters or less.`);
            return;
        }

        if (coverImage && !/image\/(jpeg|jpg|png)/.test(coverImage.type)) {
            setErrors("Cover image must be in JPEG, JPG, or PNG format.");
            return;
        }
        if (coverImage && coverImage.size > 2000000) {
            setErrors("Cover image size must be less than 2 MB.");
            return;
        }

        try {
            const newsItemId = uuid();
            let downloadURL = "";

            if (coverImage) {
                const storageRef = ref(storage, `newsfeed/${newsItemId}/${coverImage.name}`);
                const uploadTask = uploadBytesResumable(storageRef, coverImage);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error("Error uploading image:", error);
                        setErrors("An error occurred while uploading the image.");
                    },
                    async () => {
                        downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await saveNewsItem(downloadURL);
                    }
                );
            } else {
                await saveNewsItem(downloadURL);
            }

        } catch (error) {
            console.error("Error saving data:", error);
            setErrors("An error occurred while saving data.");
        }
    };

    const saveNewsItem = async (imageUrl) => {
        const newNewsItem = {
            text: newsContent,
            imageURL: imageUrl,
            categories,
            authorName: userDataFromDb.displayName,
            authorProfile: userDataFromDb.photoURL,
            comments: [],
            likes: 0,
            views: 0,
            isVerified: true,
            isApproved: true,
            createdTimeStamp: new Date().getTime(),
            authorUid: currentUser.uid
        };

        try {
            const newsfeedCollection = collection(db, "newsfeed");
            await addDoc(newsfeedCollection, newNewsItem);

            resetForm();
            setUploadComplete("Posted Successfully!");
            setIsSaved(true);
            
            // close the popup .addNewsPopup none
            setTimeout(() => {
                document.querySelector(".addNewsPopup").style.display = "none";
            }, 2000);
            

        } catch (error) {
            console.error("Error saving to Firestore:", error);
            setErrors("An error occurred while saving to the database.");
        }
    };

    function resetForm() {
        setNewsContent("");
        setCoverImage(null);
        setCategories("");
        setUploadProgress(0);
        setErrors("");
    }

    const handleImageRemove = () => {
        setCoverImage(null);
    };

    return (
        <div className="addNews">
            <div className="currentUser">
                <img src={userDataFromDb.photoURL} alt="" />
                <p>{userDataFromDb.displayName}</p>
            </div>

            <div className="publishContent">
                <form>
                    <div className="textarea">
                        <ReactQuill
                            value={newsContent}
                            onChange={handleNewsContentChange}
                            placeholder="Share Something..."
                            modules={modules}
                            formats={formats}
                            theme="snow"
                            style={{
                                height: "120px",
                                border: "none",
                                resize: "none",
                                fontSize: "12px",
                                margin: "8px 2px",
                                borderRadius: "4px",
                            }}
                        />
                    </div>

                    <div className="input-field">
                        <select
                            id="selectType"
                            value={categories}
                            onChange={(e) => setCategories(e.target.value)}
                            required
                        >
                            <option value="">Select Post Category</option>
                            <option value="Tips">Tips</option>
                            <option value="Love">Love</option>
                            <option value="Placement">Placement</option>
                            <option value="Message">Message</option>
                            <option value="Other">Other</option>
                            <option value="Notes">Notes</option>
                        </select>

                        <div className="action">
                            <div className="showEmojis">
                                <span onClick={() => setShowEmojis(!showEmojis)}>
                                    <i className="fas fa-icons"></i>
                                </span>
                            </div>

                            <div className="ChooseImage">
                                {!coverImage && (
                                    <label htmlFor="coverImage" style={{ cursor: "pointer" }}>
                                        <i className="fas fa-image-polaroid"></i>
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="emoji-picker" style={{ display: showEmojis ? "block" : "none" }}>
                        <EmojiPicker onEmojiClick={handleEmojiClick} height={"350px"} />
                    </div>

                    <div className="img-upload">
                        <input
                            hidden
                            type="file"
                            id="coverImage"
                            onChange={(e) => setCoverImage(e.target.files[0])}
                        />
                        {coverImage && (
                            <div className="smallImagePreview">
                                <img
                                    src={URL.createObjectURL(coverImage)}
                                    alt="Cover"
                                    className="small-coverImage"
                                />
                            </div>
                        )}
                        <button type="button" onClick={handleImageRemove}
                            style={{ display: coverImage ? "block" : "none" }}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>

                    <div className="Status">
                        {uploadProgress > 0 && (
                            <p className="imageuploadStatus">{uploadProgress}% uploaded</p>
                        )}
                        {uploadComplete && (
                            <p className="postedSuccessfully">{uploadComplete}</p>
                        )}
                        <p className="error">{errors}</p>
                    </div>

                    <div className="submit-btn">
                        <button type="button" onClick={() => alert("Coming Soon!")} className="ai-button">
                            <i className="fas fa-air-freshener"></i> Rewrite with AI
                        </button>

                        <button type="submit" onClick={handlePublish}>
                            Publish <i className="fas fa-paper-plane"></i>
                        </button>
                    </div>
                </form>

                
            </div>
        </div>
    );
};

export default AddNews;
