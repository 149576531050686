import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom"; // Import NavLink and useLocation
import "../style/MenuBar.scss";
import { AuthContext } from "../context/AuthContext";
import { MusicContext } from "../context/MusicContext";
import { reload } from "firebase/auth";
import { set } from "date-fns";

const CustomNavLink = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    const isExternalLink = to === '' || to.startsWith('https://');

    if (isExternalLink) {
        return (
            <div className="link">
                <a href={to} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            </div>
        );
    }

    return (
        <div className={isActive ? 'Activelink' : 'link'}>
            <NavLink to={to}>{children}</NavLink>
        </div>
    );
};

const MenuBar = () => {
    const [ConfessionIcon, setConfessionIcon] = useState("fad fa-megaphone");
    const { currentUser, userDataFromDb } = useContext(AuthContext);
    const MusicComponent = useContext(MusicContext);
    const [darkTheme, setDarkTheme] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const handleMobileMenu = () => {
        setShowMenu(!showMenu);
    }

    // if showMenu true make it falst after 5 seconds
    useEffect(() => {
        if (showMenu) {
            setTimeout(() => {
                setShowMenu(false);
            }, 15000);
        }
    }, [showMenu]);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("/chat")) {
            setDarkTheme(true);
        } else {
            setDarkTheme(false);
        }
    }, [location.pathname]);

    const developerImage = "https://avatars.githubusercontent.com/u/43730425?v=4";
    useEffect(() => {
        let count = 0;
        const interval = setInterval(() => {
            setConfessionIcon(count === 0 ? "fad fa-microphone-stand" : "fad fa-megaphone");
            count = 1 - count;
        }, 4000);

        // Clear the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, []);


    function isMobileDevice() {
        return window.innerWidth <= 768;
    }

    const handleLayoutShift = () => {
        if (isMobileDevice()) {
            window.location.reload();
        }
    };



    return (
        <div className={`sidebarLeft ${darkTheme && "darkTheme"}`}>
            <h1 data-hover="UniChat">UC</h1>

            <div className="top">

                <div className="customTitle" data-hover="Dashboard">
                    <CustomNavLink to="/" >
                        <i className="lni lni-grid-alt"></i>
                    </CustomNavLink>
                </div>

                <div className="customTitle" data-hover="Chating" onClick={handleLayoutShift}>
                    <CustomNavLink to="/dms">
                        <i className="fas fa-comment-alt-smile"></i>
                    </CustomNavLink>
                </div>

                <div className="customTitle" data-hover="News">
                    <CustomNavLink to="/news">
                    <i class="fas fa-rss"></i>
                    </CustomNavLink>
                </div>

                <div className="customTitle" data-hover="Networking">
                    <CustomNavLink to="/networking">
                        <i class="fas fa-chart-network"></i>
                    </CustomNavLink>
                </div>

                <div className="customTitle" data-hover="CodeSync">
                        <CustomNavLink to="/groupcoding">
                            <i className="fad fa-code"></i>
                        </CustomNavLink>
                    </div>

                <div className="desktop-menu">
                    <div className="customTitle" data-hover="Dating">
                        <CustomNavLink to="/dating">
                            <i className="far fa-glass-cheers"></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Confession">
                        <CustomNavLink to="/confession">
                            <i className={ConfessionIcon}></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Room's">
                        <CustomNavLink to="/groupchat" className="new">
                            <i className="fad fa-igloo"></i>
                        </CustomNavLink>
                    </div>

                    {/* group coding */}

                  

                    <div className="customTitle" data-hover="Food">
                        <CustomNavLink to="/food">
                            <i className="fas fa-burger-soda"></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Startup's">
                        <CustomNavLink to="/startup">
                            <i class="fad fa-rocket"></i>
                        </CustomNavLink>
                    </div>
                </div>

                <div className="customTitle tooglebar" onClick={handleMobileMenu} data-hover="All Menu">
                    <CustomNavLink to="#" onClick={handleMobileMenu}>
                        {showMenu ? <i class="fad fa-times toggleAnimation"></i> : <i class="fad fa-bars toggleAnimation"></i>}

                    </CustomNavLink>
                </div>

                {/* 
                <div className="customTitle" data-hover="Shoping">
                    <CustomNavLink to="/shop">
                        <i className="lni lni-cart-full"></i>
                    </CustomNavLink>
                </div> */}



                {/* <div className="customTitle" onClick={() => {window.open("https://enally.in/files-manager/", "_blank")}} data-hover="Notes">
                    <CustomNavLink to="/food">
                        <i className="fal fa-backpack" onClick={() => {window.open("https://enally.in/files-manager/", "_blank")}}></i>
                    </CustomNavLink>
                </div> */}

            </div>

            <div className="mobile-menu" style={{ display: showMenu ? 'flex' : 'none' }}>
                 
                 <div className="more">
                    <h3>More Features</h3>
                    

                 </div>
                
                <div className="center">

                    {/* group coding */}

                    
                    
            
                    <div className="customTitle" data-hover="Dating">
                        <CustomNavLink to="/dating">
                            <i className="far fa-glass-cheers"></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Confession">
                        <CustomNavLink to="/confession">
                            <i className={ConfessionIcon}></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Room's">
                        <CustomNavLink to="/groupchat" className="new">
                            <i className="fad fa-igloo"></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Food">
                        <CustomNavLink to="/food">
                            <i className="fas fa-burger-soda"></i>
                        </CustomNavLink>
                    </div>

                    <div className="customTitle" data-hover="Startup's">
                        <CustomNavLink to="/startup">
                            <i class="fad fa-rocket"></i>
                        </CustomNavLink>
                    </div>

                    {/*  Settings */}

                    <div className="customTitle" data-hover="Settings">
                        <CustomNavLink to="/settings">
                            <i class="fas fa-cog"></i>
                        </CustomNavLink>
                    </div>

                    

                </div>

            </div>


            <div className="bottom">
                <p className="beta" data-hover="Welcome to our Beta Version! With your feedback, we'll make it better! Developed With Love by Prashant Kumar">Beta</p>

                <div className="developerInfo" onClick={() => { window.open("https://www.instagram.com/prashantpkumar/", "_blank") }}>
                    <div className="image-container-2"> </div>
                    <div className="image-container-3"> </div>
                    <div className="image-container-4"> </div>
                    <div className="image-container-5"> </div>
                    <div className="image-container">
                        <img src={developerImage} alt="Prashant" />
                    </div>
                </div>


                <div className="customTitle download" data-hover="GetApp">
                    <CustomNavLink to="https://enally.in/chatapp/download/" target="_blank">
                        <i class="lni lni-android-original"></i>
                    </CustomNavLink>
                </div>
                
                {
                    userDataFromDb.demoUser ? (
                        ""
                    ) : (
                        <div className="customTitle" data-hover="Edit">
                            <CustomNavLink to="/settings">
                                <i class="fas fa-cog"></i>
                            </CustomNavLink>
                        </div>
                    )
                }

                {
                    userDataFromDb.isAdmin ? <CustomNavLink to="/admin"> <i className="fas fa-user-shield"></i> </CustomNavLink> : ""
                }

                <div className="customTitle" data-hover="Support">
                    <CustomNavLink to="/support">
                        <i className="far fa-bug"></i>
                    </CustomNavLink>
                </div>

            </div>
        </div>
    );
};

export default MenuBar;
