import React, { useEffect, useState } from 'react';
import '../style/Networking.scss';

const Networking = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [
        "https://cdn3d.iconscout.com/3d/premium/thumb/student-5565610-4715116.png",
        "https://cdn3d.iconscout.com/3d/premium/thumb/student-girl-5565708-4715098.png",
        "https://cdn3d.iconscout.com/3d/premium/thumb/customer-service-5565736-4715086.png"
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prev) => (prev + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    const handleJoinButton = () => {
        window.open("https://enally.in/contact", "_blank");
    }

    return (
        <div className="networking-container">
            <div className="content">
                <div className="thumb-icon">👍</div>
                <h3 className="still-text">WE'RE STILL</h3>
                <h1 className="main-title">Cooking Our Website.</h1>
                <p className="description">We are looking for developers, UI/UX designers, and social media managers. Stay tuned.</p>

                <img className="rotating-image" src={images[currentImage]} alt="Networking" />

                <button className="cta-button" onClick={handleJoinButton}>
                    <i className="lni lni-handshake"></i> Join Us
                </button>

                <div className="social-icons">
                    <a href="https://www.instagram.com/enally.in/" className="instagramShare" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-instagram"></i>
                          @enally.in
                    </a>
                    <a href="https://www.linkedin.com/company/enally-in/" className="linkedinShare" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-linkedin"></i>
                        enally-in
                    </a>
                    <a href="https://github.com/03prashantpk" className="githubShare" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-github"></i>
                        @03prashantpk
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Networking;
