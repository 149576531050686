import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import PasswordReset from "./pages/PasswordReset";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import MenuBar from "./components/MenuBar";
import Dashboard from "./pages/Dashboard";
import UpdateNews from "./admin/UpdateNews";
import Admin from "./admin/Admin";
import UniNews from "./pages/Uninews";
import "./style/style.scss";
import "./style/LoginAndRegister.scss";
import "./style/Home.scss";
import ManageAndApproveNews from "./admin/ManageAndApproveNews";
import Users from "./admin/Users";
import { MusicContext, Music } from "./context/MusicContext";
import DatingProfile from "./pages/DatingProfile";
import Support from "./pages/Support";
import OldNews from "./pages/OldNewsPaper";
import Food from "./pages/Food";
import Shop from "./pages/Shop";
import Confession from "./pages/Confession";


// group coding
import GroupCoding from "./pages/GroupCoding/GroupCoding";

// Group Chat
import IndexPage from "./pages/GroupChat/IndexPage";
import NewRoomPage from "./pages/GroupChat/NewRoomPage";
import ExistRoomPage from "./pages/GroupChat/ExistRoomPage";
import ChatRoomPage from "./pages/GroupChat/ChatRoomPage";
import ManageSupport from "./admin/ManageSupport";
import Startup from "./pages/Startup";


// Dating
import Dating_new from "./pages/Dating/Dating_new";
import Settings from "./pages/UserSettings/Settings";

// import animation js file
import initparticles from './animation';
import NearbyRestaurants from "./pages/NearbyRestaurants";
import Networking from "./pages/Networking";


// ProtectedRoute component outside the App component
const ProtectedRoute = ({ children }) => {
  const { currentUser, userDataFromDb } = useContext(AuthContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return children;
};

const ProtectedRouteAdmin = ({ children }) => {
  const { currentUser, userDataFromDb } = useContext(AuthContext);

  if (!userDataFromDb.isAdmin) {
    return <Navigate to="/login" />;
  }
  return children;
}

function App() {
  
  useEffect(() => {
    initparticles();

  }, []);


  return (
    <MusicContext.Provider value={Music}>
    <BrowserRouter>
      <div className="App">
        <MenuBar />
        <div className="centerComponent">
          <Routes>
            {/* Landing & Services Non-Protected Page */}
            <Route path="/" element={<Dashboard/>} />
            <Route path="support" element={<Support/>} />
            {/* Landing & Services Non-Protected Page */}

            {/* Users Protected */}
            <Route path="/dms" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="news" element={<ProtectedRoute><UniNews /></ProtectedRoute>} />
            <Route path="dating" element={<ProtectedRoute><DatingProfile /></ProtectedRoute>} />
            <Route path="dating-new" element={<ProtectedRoute><Dating_new/></ProtectedRoute>} />
            <Route path="food" element={<ProtectedRoute><Food /></ProtectedRoute>} />
            <Route path="/nearby-restaurants/:university" element={<ProtectedRoute><NearbyRestaurants /></ProtectedRoute>} />
            <Route path="oldNews" element={<ProtectedRoute><OldNews /></ProtectedRoute>} />
            <Route path="shop" element={<ProtectedRoute><Shop /></ProtectedRoute>} />
            <Route path="confession" element={<ProtectedRoute><Confession /></ProtectedRoute>} />
            <Route path="startup" element={<ProtectedRoute><Startup /></ProtectedRoute>} />
            <Route path="settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="networking" element={<ProtectedRoute><Networking/></ProtectedRoute>} />

            {/* group coding */} 
            <Route path="groupcoding" element={<ProtectedRoute><GroupCoding /></ProtectedRoute>} />


            {/* Group Chat Routes */}

            <Route path="groupchat" element={<ProtectedRoute><IndexPage /></ProtectedRoute>} />
            <Route path="newroom" element={<ProtectedRoute><NewRoomPage /></ProtectedRoute>} />
            <Route path="exroom" element={<ProtectedRoute><ExistRoomPage /></ProtectedRoute>} />
            <Route path="chat/:roomcode" element={<ProtectedRoute><ChatRoomPage /></ProtectedRoute>} />

            {/* Users Protected */}

            {/* Non-Protected */}
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="password-reset" element={<PasswordReset />} />
            {/* Non-Protected */}
      
            {/* Admin Routes (Pages) */}  
            <Route path="admin" element={<ProtectedRouteAdmin><Admin /></ProtectedRouteAdmin>} />
            <Route path="updatenews" element={<ProtectedRouteAdmin><UpdateNews /></ProtectedRouteAdmin>} />
            <Route path="manageNews" element={<ProtectedRouteAdmin><ManageAndApproveNews /></ProtectedRouteAdmin>} />
            <Route path="manageusers" element={<ProtectedRouteAdmin><Users/></ProtectedRouteAdmin>} />
            <Route path="manage-support" element={<ProtectedRouteAdmin><ManageSupport/></ProtectedRouteAdmin>} />
            
            {/* Admin Routes (Pages)*/}

            {/* Add a default redirect to the home page for unknown routes */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
    </MusicContext.Provider>
  );
}

export default App;
