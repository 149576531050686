import React, { useState, useContext } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { doc, updateDoc, arrayRemove, arrayUnion } from "firebase/firestore";
import { db } from "../../firebase";

const Comments = ({ postId, comments, onCommentAdded, showComments  }) => {
    const [showAllComments, setShowAllComments] = useState(false);
    const [newComment, setNewComment] = useState('');
    const { currentUser, userDataFromDb } = useContext(AuthContext);

    const handleAddComment = async () => {
        if (newComment.trim() === '') return;

        const postRef = doc(db, 'newsfeed', postId);
        const newCommentObj = {
            text: newComment,
            userId: currentUser.uid,
            timestamp: Date.now(),
            authorFace: userDataFromDb.photoURL,
            authorName: userDataFromDb.displayName,
            authorId: currentUser.uid
        };

        await updateDoc(postRef, {
            comments: arrayUnion(newCommentObj)
        });

        onCommentAdded(newCommentObj);
        setNewComment('');
    };


    const handleDeleteComment = async (comment) => {

        const confirm = window.confirm('Are you sure you want to delete this comment?');
        if (!confirm) return;


        const postRef = doc(db, 'newsfeed', postId);

        
        await updateDoc(postRef, {
            comments: arrayRemove(comment)
        });

        onCommentAdded(null, comment);
    };

    const toggleComments = () => {
        setShowAllComments(!showAllComments);
    };

    const convertTimestamp = (timestamp) => {
        const now = Date.now();
        const diff = now - timestamp;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (years > 0) return `${years}y ago`;
        if (months > 0) return `${months}m ago`;
        if (days > 0) return `${days}d ago`;
        if (hours > 0) return `${hours}h ago`;
        if (minutes > 0) return `${minutes}m ago`;
        return `${seconds}s ago`;
    };

    return (
        <div className="comments-section">
            {comments.length > 0 && (
                <div className="recent-comment" onClick={toggleComments}>
                    <div className="lastComment">
                        <img
                            src={comments[comments.length - 1]?.authorFace}
                            alt={comments[comments.length - 1]?.authorName || 'Author'}
                            width="24px"
                        />
                        <div>
                            <div className="time">
                                {convertTimestamp(comments[comments.length - 1]?.timestamp)} 
                               
                            </div>
                            <div className="authorName">
                                {comments[comments.length - 1]?.authorName || 'Anonymous'}
                            </div>
                            <div className="comment-text">
                                {comments[comments.length - 1]?.text.split(' ').slice(0, 6).join(' ')}...
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {
                comments.length === 0 && (
                    <div className="recent-comment">
                        <div className="lastComment" onClick={toggleComments}>
                            <p>Add a comment...</p>
                        </div>
                    </div>
                )
            }


            {showAllComments && (
                <div className="comments-popup">
                    <div className="popup-content">
                        <button className="close-popup" onClick={toggleComments}>
                            <i class="fas fa-times"></i>
                        </button>
                        <h3>Comments</h3>

                        <div className="allComments">
                            {comments.map((comment, index) => (
                                <div key={index} className="comment">
                                    <div className="Mcontent">
                                        <img src={comment.authorFace} alt={comment.authorName} width={"20px"} />

                                        <div className="comment-content">

                                            <p className="time">
                                                {convertTimestamp(comment.timestamp)}
                                            </p>
                                            <h4>{comment.authorName}</h4>
                                            <p>{comment.text}</p>
                                        </div>
                                    </div>
                                    {currentUser.uid === comment.userId && (
                                        <button onClick={() => handleDeleteComment(comment)} className='delete-comment'>
                                            <i class="lni lni-eraser"></i>
                                        </button>
                                    )}
                                </div>
                            ))}

                            {comments.length === 0 && (
                                <div className="comment">
                                    <div className="Mcontent">
                                        <p>No comments yet...</p>
                                    </div>
                                </div>
                            )}

                        </div>


                        <div className="add-comment">
                            <input
                                type="text"
                                placeholder="Add a comment..."
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                            />
                            <button onClick={handleAddComment}>Post <i class="fas fa-paper-plane"></i></button>
                        </div>

                    </div>



                </div>
            )}


        </div>
    );
};

export default Comments;