import React from 'react';
import '../../style/GroupCoding/GroupCoding.scss';

const GroupCoding = () => {
    const handleJoinButton = () => {
        window.open("https://enally.in/contact", "_blank");
    }

    return (
        <div className="groupcoding-container">
            <div className="content">
                <div className="thumb-icon">👨‍💻</div>
                <h3 className="still-text">WE'RE STILL</h3>
                <h1 className="main-title">Building Group Coding.</h1>
                <p className="description">Coming Soon! Stay tuned for updates.</p>

                <button className="cta-button" onClick={handleJoinButton}>
                    <i className="lni lni-handshake"></i> Join Us
                </button>

                <div className="social-icons">
                    <a href="https://www.instagram.com/enally.in/" className="instagramShare" target="_blank" rel="noopener noreferrer">
                        <i className="lni lni-instagram-fill"></i> @enally.in
                    </a>
                    <a href="https://www.linkedin.com/company/enally-in/" className="linkedinShare" target="_blank" rel="noopener noreferrer">
                        <i className="lni lni-linkedin-original"></i> enally-in
                    </a>
                    <a href="https://github.com/03prashantpk" className="githubShare" target="_blank" rel="noopener noreferrer">
                        <i className="lni lni-github-original"></i> @03prashantpk
                    </a>
                </div>
            </div>
        </div>
    );
}

export default GroupCoding;
